import {
  HelperPostResourceAuthor,
  UserSharedCurrentUser,
  UserSharedUser,
} from "./types";

export const isVisitableUserProfile = (
  user: UserSharedUser | HelperPostResourceAuthor,
  currentUser: UserSharedCurrentUser,
) => {
  if (!currentUser?.is_all_public_feature_accessible) return false;

  return user.is_profile_visitable;
};
