import React, { useContext } from "react";
import { ButtonProps, Button } from "./Button";
import { Link } from "@chakra-ui/react";
import {
  newUserDatabaseAuthenticationRegistrationPath,
  newUserPersonalInfoApplicationPath,
} from "../../../../../routes";
import { UserSharedCurrentUser } from "../../lib/types";
import { FlipperContext } from "../../../../shared/lib/FlipperContext";

const RegistrationButton = ({
  currentUser,
  ...props
}: { currentUser: UserSharedCurrentUser } & ButtonProps) => {
  const href = currentUser?.is_all_public_feature_accessible
    ? ""
    : currentUser?.is_temporary
      ? newUserPersonalInfoApplicationPath()
      : newUserDatabaseAuthenticationRegistrationPath();

  const flipper = useContext(FlipperContext);

  const label = currentUser?.is_all_public_feature_accessible
    ? ""
    : currentUser?.is_temporary && !currentUser?.applicationing
      ? "本人確認を申請する"
      : flipper.free_registration
        ? "会員登録（無料）する"
        : "会員登録する";

  return (
    <Button as={Link} textDecoration="none" href={href} {...props}>
      {label}
    </Button>
  );
};

export default RegistrationButton;
