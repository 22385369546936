import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import { postPath } from "../../../../../routes";
import { SharedPost, UserSharedCurrentUser } from "../../lib/types";
import BarChartIcon from "../icons/BarChatIcon";
import ChatBubbleIcon from "../icons/ChatBubbleIcon";
import BookmarkButton from "./BookmarkButton";
import Highlight from "./Highlight";
import PostResourceAvatar from "./PostResourceAvatar";
import PostResourceDisplayName from "./PostResourceDisplayName";
import Tag from "./Tag";

const PostSummaryCardDetail = ({
  post,
  query = [],
  currentUser,
}: {
  post: SharedPost;
  query?: string[];
  currentUser: UserSharedCurrentUser;
}) => {
  return (
    <Box
      data-testid="old"
      backgroundColor="#FFF"
      px={{ base: 5, md: 9 }}
      py={{ base: 4, md: 7 }}
      border="1px solid #DCE5E3"
      borderRadius="8px"
      as="a"
      display="block"
      textDecoration="none"
      color="inherit"
      href={postPath(post.code)}
      _hover={{
        outline: "4px solid",
        outlineColor: "primary",
      }}
    >
      {post.questionnaire_accepting && (
        <HStack
          borderRadius={2}
          w="fit-content"
          pl={{ base: 2, md: 2.5 }}
          pr={{ base: 2.5, md: 3 }}
          py={0.5}
          gap={0.5}
          mb={{ base: 3, md: 4 }}
          color="white"
          bgColor="#009EC2"
        >
          <BarChartIcon boxSize={{ base: "20px", md: "22px" }} />
          <Box mt={0.5} fontSize={{ base: "xs", md: "sm" }} fontWeight="bold">
            アンケート 実施中
          </Box>
        </HStack>
      )}
      <Box mb={1}>
        {post.is_resolved && (
          <Box
            as="span"
            display="inline-block"
            height="fit-content"
            pt={1}
            pb={0.5}
            px={2}
            fontWeight="bold"
            fontSize={{ base: "xs", md: "sm" }}
            color="white"
            bgColor="#7E7E7E"
            borderRadius={2}
            verticalAlign="middle"
            mr={{ base: 1, md: 2 }}
          >
            解決済み
          </Box>
        )}
        <Text
          fontWeight="700"
          verticalAlign="middle"
          noOfLines={2}
          fontSize={{ base: "md", md: "xl" }}
          display="inline"
        >
          <Highlight query={query}>{post.title}</Highlight>
        </Text>
      </Box>
      <Flex gap={1.5} pb={3} wrap="wrap">
        {post.tags.map((tag) => (
          <Tag key={tag.id}>{tag.name}</Tag>
        ))}
      </Flex>
      <Text noOfLines={{ base: 4, md: 3 }} fontSize={{ base: "sm", md: "md" }}>
        <Highlight query={query}>{post.content}</Highlight>
      </Text>
      <Flex gap={3} justify="space-between" align="center" mt={3}>
        <HStack gap={3}>
          <PostResourceAvatar
            w={12}
            h={12}
            author={post.author}
            loading="lazy"
            ignoreFallback
          />
          <Box>
            <PostResourceDisplayName author={post.author} />
            <Text fontSize="xs" color="#848484">
              {dayjs(post.created_at).format("L LT")}
            </Text>
          </Box>
        </HStack>
        <HStack justify={{ base: "space-between" }}>
          <HStack gap={1}>
            <ChatBubbleIcon boxSize="1.125rem" />
            <Text fontSize={{ base: "xs", md: "sm" }} pt="3px">
              {post.comments_count}
            </Text>
          </HStack>
          <BookmarkButton
            post_code={post.code}
            bookmarked={post.is_bookmarked}
            currentUser={currentUser}
          />
        </HStack>
      </Flex>
    </Box>
  );
};

export default PostSummaryCardDetail;
