import React, { ReactNode, useContext, useEffect } from "react";
import useFlash from "../../lib/useFlash";
import RegistrationRequiredModal from "../atoms/RegistrationRequiredModal";
import { useDisclosure } from "@chakra-ui/react";
import { UserSharedCurrentUser } from "../../lib/types";
import { FlipperContext } from "../../../../shared/lib/FlipperContext";

const Application = ({
  children,
  flash = {},
  currentUser = null,
}: {
  children: ReactNode;
  flash: Record<string, string>;
  currentUser?: UserSharedCurrentUser;
}) => {
  const showFlash = useFlash();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const flipper = useContext(FlipperContext);

  useEffect(() => showFlash(flash), [showFlash, flash]);
  useEffect(() => {
    if (
      flipper.promoto_register &&
      new URLSearchParams(location.search).get("open_registration_require_modal") === "1"
    ) {
      onOpen();
    }
  }, [flipper.promoto_register, onOpen]);

  const removeNotApplicationingQueryParams = () => {
    const url = new URL(location.href);
    // location.hrefを使うと不要なリロードが走るためreplaceStateを使う
    url.searchParams.delete("open_registration_require_modal");
    window.history.replaceState(null, "", url);
  };

  return (
    <>
      <RegistrationRequiredModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          // open_registration_require_modal=1を削除しないと「戻る」とかしたときに再度モーダルが表示されてしまうため削除
          removeNotApplicationingQueryParams();
        }}
        currentUser={currentUser}
      />
      {children}
    </>
  );
};

export default Application;
