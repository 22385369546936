import { Box, HStack, RadioGroup, Stack } from "@chakra-ui/react";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Control, Controller } from "react-hook-form";
import { UserSharedApprovedCurrentUser } from "../../shared/lib/types";
import PostResourceAvatar, {
  PostResourceAvatarProps,
} from "../../shared/components/atoms/PostResourceAvatar";
import { FormLabel, InputError, Radio } from "../../shared/components/atoms/form";

const AvatarPreview = ({
  author,
}: {
  author: PostResourceAvatarProps["author"];
}) => {
  return (
    <Box bgColor="#F5F7F7" py={3} px={4} borderRadius={2}>
      <Box fontSize="xs">表示用プレビュー</Box>
      <HStack mt={1} gap={3}>
        <PostResourceAvatar boxSize={12} author={author} />
        <Box fontWeight="bold">{author.display_name}</Box>
      </HStack>
    </Box>
  );
};

const AnonymousField = ({
  control,
  anonymous,
  currentUser,
  label,
  showPreview = true,
}: {
  control: Control;
  anonymous: string;
  currentUser: UserSharedApprovedCurrentUser;
  label: string;
  showPreview?: boolean;
}) => {
  return (
    <Stack gap={2}>
      <FormLabel required>{label}</FormLabel>
      <Controller
        name="anonymous"
        control={control}
        render={({ field: { ref, ...field }, fieldState }) => (
          <>
            <RadioGroup {...field}>
              <Stack>
                <Radio value="0" isInvalid={fieldState.error != null} ref={ref}>
                  実名
                </Radio>
                <Radio value="1" isInvalid={fieldState.error != null} ref={ref}>
                  ニックネーム
                </Radio>
              </Stack>
            </RadioGroup>
            {fieldState.error != null && (
              <InputError>{fieldState.error.message}</InputError>
            )}
          </>
        )}
      />
      {anonymous === "" ? (
        <></>
      ) : showPreview && anonymous === "0" ? (
        <AvatarPreview
          author={{
            ...currentUser,
            display_image_url: currentUser.profile_image_url,
            display_name: currentUser.full_name,
            anonymous: false,
            is_guest: false,
          }}
        />
      ) : showPreview && anonymous === "1" ? (
        <AvatarPreview
          author={{
            ...currentUser,
            display_image_url: currentUser.anonymous_image_url,
            display_name: currentUser.nickname,
            anonymous: true,
            is_guest: false,
          }}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default AnonymousField;
